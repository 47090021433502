import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import "./styles.scss";
import { Link } from "react-router-dom";
AOS.init();
function Dropshiper(props) {
  return (
    <Fragment>
      <section className="sellerStyle mb-1 mainSection">
        <Container>
          <Row>
            <Col sm={12} md={7}>
              <h1 className="sectitle">
                Qui peut faire le dropshipping ?
              </h1>
              <ul className="navWhy">
                <li>
                  <i class="bi bi-check-circle-fill iCon"></i> Les jeunes filles
                  et hommes désirant avoir une source de revenus
                </li>
                <li>
                  <i class="bi bi-check-circle-fill iCon"></i> Les aspirants
                  entrepreneurs, les femmes au foyers, les salariés:
                </li>
                <ul className="dezire">
                  <li>Désirant avoir des revenus additionnels</li>
                  <li>Désirant diversifier leurs activités</li>
                </ul>
              </ul>
              <div><Link to={'/comment-ça-marche'} className="rounded-pill btn btn-light readMoreBnt text-danger font-weight-bold">En savoir plus ...</Link></div>
            </Col>
            <Col sm={12} md={5}>
              {" "}
              <img
                alt="DropShiping."
                src="/seller.jpg"
                className="img-fluid"
              />
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
}

export default Dropshiper;
