import "bootstrap/dist/css/bootstrap.min.css";
import { Fragment } from "react";
import "./styles.scss";
import Layout from "./layout/Layout";
function App() {
  return (
    <Fragment>
     <Layout/>
    </Fragment>
  );
}

export default App;
