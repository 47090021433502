import React, { Fragment } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./component/home/Home";
import HowtodoPage from "./component/howtodo/HowtodoPage";
import FaqPage from "./component/faq/FaqPage";
import ContactPage from "./component/contact/ContactPage";
function RouterNav(props) {
  return (
    <Fragment>
      <Routes>
        <Route>
          <Route index element={<Home />} />
          <Route path="comment-ça-marche" element={<HowtodoPage />} />
          <Route path="faq" element={<FaqPage />} />
          <Route path="contact" element={<ContactPage />} />
        </Route>
      </Routes>
    </Fragment>
  );
}

export default RouterNav;
