import React, { Fragment } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./styles.scss";
import Image from 'react-bootstrap/Image';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
AOS.init();

function Banner(props) {
  return (
    <Fragment>
      <section className="bannerStyle  bannerSection bg-primaryx py-3">
        <Container>
          <Row>
            <Col md="6">
              <div className="boxHeaderTitle" data-aos="fade-right">
                <h1 className="nameTitlex"><b>E-Triangle</b>, Le moyen le plus simple de demarrer un business en ligne</h1>
                <h1 className="textHeaderTitleX">
                 
                </h1>
                <h6 className="subTextHeader">
                  Nous mettons à votre disposition des milliers de produits de
                  plusieurs categories que vous pouvez directement vendre auprès de
                  vos clients sans vous souciez du stock et de la livraison.
                  <br></br>Nous gérons tout le reste pour vous.
                </h6>
                <p>
                  {/* <Button variant="danger" size="lg" className="rounded-pill ">
                    Inscrivez vous gratuitement{" "}
                  </Button> */}
                  <a href="https://dropshipping.e-triangle.com/register" target="_blank" rel="noopener noreferrer" className="rounded-pill btn btn-danger "> Inscrivez vous gratuitement{" "}</a>
                </p>
              </div>
            </Col>
            <Col md="6">
              <div className="boxHeaderImg" data-aos="fade-left">
                {/* <img
                  alt="DropShiping."
                  src="african.jpg"
                  className="img-fluid roundedCircle "
                /> */}
              <Image src="/african.jpg" rounded  fluid />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
}

export default Banner;
