import React, { Fragment } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./styles.scss";
import ContactPageHeader from "./ContactPageHeader";
import Form from "react-bootstrap/Form";
import Image from 'react-bootstrap/Image';
function ContactPage(props) {
  return (
    <Fragment>
      <ContactPageHeader />
      <section className="ContactPageSection">
        <Container>
          <Row>
            <Col sm={12} md={6}>
            <Image src="mail_sender2.png" className="img-fluid" />
            </Col>
            <Col sm={12} md={6}>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Nom & Prénoms <b className="text-danger">(*)</b></Form.Label>
                  <Form.Control type="textName" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Adresse Email <b className="text-danger">(*)</b></Form.Label>
                  <Form.Control type="email" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Téléphone Mobile <b className="text-danger">(*)</b></Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Sujet</Form.Label>
                  <Form.Control type="email" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Message <b className="text-danger">(*)</b></Form.Label>
                  <Form.Control as="textarea" rows={3} />
                </Form.Group>
                <Form.Group className="mb-5">
                <Button variant="dark" type="submit">
                  Envoyer <i class="fa-solid fa-paper-plane"></i>
                </Button> 
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
}

export default ContactPage;
