import React, { Fragment } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "./styles.scss";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
AOS.init();
function Howtodo(props) {
  return (
    <Fragment>
      <section className="sellerStyle mb-2 mainSectionX HowtodoSection">
        <Container>
          <Row>
            <Col>
              <h1 className="text-center sectitle mb-5 text-dark">
                Comment vendre les produits E-Triangle ?
              </h1>
            </Col>
          </Row>
          
          <Row>
            <Col className="HowtodoCard" sm={12}  md={3}>
              <Card className="border-0 shadow cardStep" data-aos="zoom-in">
                <div className="d-flex justify-content-center">
                  <i class="fa-solid fa-1 howIcon mt-3"></i>
                </div>
                <Card.Body className="text-center">
                  <Card.Title className="text-center font-weight-bold">
                  <i class="bi bi-person-down"></i> Inscription
                  </Card.Title>
                  <Card.Text>S'insrire sur l'application E-Triangle</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className="HowtodoCard" sm={12}  md={3}>
              <Card className="border-0 shadow cardStep" data-aos="zoom-in">
                <div className="d-flex justify-content-center">
                  <i class="fa-solid fa-2 howIcon mt-3"></i>
                </div>

                <Card.Body className="text-center">
                  <Card.Title className="text-center font-weight-bold">
                  <i class="bi bi-check2-square"></i> Selection de produits
                  </Card.Title>
                  <Card.Text>Selectionner les produits qui vous interessent</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className="HowtodoCard" sm={12}  md={3}>
              <Card className="border-0 shadow cardStep" data-aos="zoom-in">
                <div className="d-flex justify-content-center">
                  {/* <i class="bi bi-download howIcon"></i> */}
                  <i class="fa-solid fa-3 howIcon mt-3"></i>
                </div>
                <Card.Body className="text-center">
                  <Card.Title className="text-center font-weight-bold">
                  <i class="bi bi-download mt-3"></i> Telechargement
                  </Card.Title>
                  <Card.Text>Telecharger les photos et videos de produits</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className="HowtodoCard" sm={12} md={3}>
              <Card className="border-0 shadow cardStep" data-aos="zoom-in">
                <div className="d-flex justify-content-center">
                  <i class="fa-solid fa-4 howIcon mt-3"></i>
                </div>
                <Card.Body className="text-center">
                  <Card.Title className="text-center font-weight-bold">
                  <i class="bi bi-cash-coin"></i> Debuter votre marketing
                  </Card.Title>
                  <Card.Text>Et debuter votre marketing</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
}

export default Howtodo;
