import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";

function HowtodoHeader(props) {
    return (
        <Fragment>
            <section className="HowtodoHeaderSection">
                <Container>
                    <Row>
                        <Col><h1 className="text-center ftTitle">Comment ça marche avec E.Triangle</h1>
                        <h3 className="text-center">Gagner de l'argent en ligne</h3>
                        
                        </Col>
                    </Row>
                </Container>
            </section>
            
        </Fragment>
    );
}

export default HowtodoHeader;