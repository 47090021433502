import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
function ContactPageHeader(props) {
  return (
    <Fragment>
      <section className="ContactPageHeaderSection">
        <Container>
          <Row>
            <Col>
              <h1 className="text-center ftTitle">
                Nous sommes là pour vous aider
              </h1>
              <h3 className="text-center">
                N'hésitez pas à nous contacter ou à nous écrire ici.
              </h3>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
}

export default ContactPageHeader;
