import React, { Fragment } from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";
import "./styles.scss";
function Footer(props) {
  return (
    <Fragment>
      <section className="footerStyle py-3  shadow-lg">
        
        <Container>
          <Row>
            <Col sm={12} md={4}>
              <h1 className="eTriangle">E-Triangle</h1>
              <ul className="footerSocio">
                <li>
                  <i class="bi bi-facebook icosocio"></i>
                </li>
                <li>
                  <i class="bi bi-instagram icosocio"></i>
                </li>
                <li>
                  <i class="bi bi-twitter icosocio"></i>
                </li>
                <li>
                  <i class="bi bi-youtube icosocio"></i>
                </li>
              </ul>
            </Col>
            <Col sm={12} md={4}>
              <ul className="footerNav">
                <li>Accueil</li>
                <li>Comment ça marche</li>
                <li>FAQ</li>
                <li>Contact</li>
                <li></li>
              </ul>
            </Col>
            <Col sm={12} md={4}>
              <ul className="footerContact">
                <li>
                  {" "}
                  <b>
                    <i class="bi bi-pin-map-fill"></i> Adresse :{" "}
                  </b>{" "}
                  Angre 8e tranche derrière l'école Fred et Popee, Cocody,
                  Abidjan, Côte d'Ivoire
                </li>
                <li>
                  {" "}
                  <b>
                    <i class="bi bi-telephone-fill"></i> Téléphone :
                  </b>{" "}
                  +225 05 76 88 06 70
                </li>
                <li>
                  {" "}
                  <b>
                    <i class="bi bi-envelope-at"></i> Email :
                  </b>{" "}
                  contact@e-triangle.com
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col sm={12} className="d-flex justify-content-between mt-5">
              <div className="imgFooter">
                <p className="copy">Copyright © 2023 E-Triangle.com, INC.</p>
              </div>
              <div>
                <p className="made">
                  Made with love <i class="bi bi-heart-fill text-light"></i> by
                  LeetQod
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
}

export default Footer;
