import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Fragment } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./styles.scss";
import { Link } from "react-router-dom";
function HeaderNavbar(props) {
  return (
    <Fragment>
      <section className="headerStyle shadow-sm" bg="light" variant="light">
        <Container>
          <Row>
            <Col>
              <Navbar collapseOnSelect expand="lg">
                <Container>
                  <Navbar.Brand href="#home">
                    <img src="Logo_e-triangle.png" alt="" className="e-logo" />
                  </Navbar.Brand>
                  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                  <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                      <Link to="/" className="nav-link">
                        Acceuil
                      </Link>
                      <Link to="/comment-ça-marche" className="nav-link">
                        Comment ça marche
                      </Link>
                      <Link to="/faq" className="nav-link">
                        FAQ
                      </Link>
                      <Link to="/contact" className="nav-link">
                        Contact
                      </Link>
                    </Nav>
                    <Nav>
                      {/* <Link
                        to="/contact"
                        className="nav-link "
                      >
                        Se connecter
                      </Link> */}
                      {/* <Button variant="outline-danger" size="lg" className="rounded-pill ">
                     
                  </Button> */}
                  <a href="https://dropshipping.e-triangle.com/login" target="_blank" rel="noopener noreferrer" className="rounded-pill btn btn-outline-danger ">Se connecter</a>

                    </Nav>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
}

export default HeaderNavbar;
