import React, { Fragment } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./styles.scss";
import { Link } from "react-router-dom";
function Community(props) {
  return (
    <Fragment>
      <section className="mb-5 comSection ">
        <Container  className="mainCom">
          <Row>
            <Col sm={12}  md={6}>
              <h1 className="sectitle">
                Rejoins la communauté des vendeurs E-Triangle
              </h1>
              <p className="comText">
                Vous etes jeune, vous souhaitez avoir une source de revenus ? Vous
                souhaitez diversifier vos activités ?{" "}Alors incrivez-vous gratuitement pour rejoindre la communauté
              </p>
              <Link to={"https://dropshipping.e-triangle.com/login"} className="rounded-pill btn btn-outline-danger joinComBtn">
                Je rejoins la communauté
              </Link>
            </Col>
            <Col className="bg-dangerx" sm={12} md={6}>
              <img
                alt="DropShiping."
                src="/community.jpg"
                className="img-fluid imgComx"
              />
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
}

export default Community;
