import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import HowtodoHeader from "./HowtodoHeader";
import "./styles.scss";
function HowtodoPage(props) {
    return (
        <Fragment>
            <HowtodoHeader/>
            <section className="HowtodoPageSection">
                <Container>
                    <Row>
                        <Col></Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    );
}

export default HowtodoPage;