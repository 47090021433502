import React, { Fragment } from "react";
import HeaderNavbar from "../component/header/HeaderNavbar";
import Footer from "../component/footer/Footer";
import RouterNav from "../RouterNav";

function Layout(props) {
  return (
    <Fragment>
      <HeaderNavbar />
      <RouterNav />
      <Footer />
    </Fragment>
  );
}

export default Layout;
