import React, { Fragment } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "./styles.scss";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
AOS.init();
function Categorie(props) {
  return (
    <Fragment>
      <section className="p-3 mainSection categorieSection parallax ">
        <Container>
          <Row>
            <Col sm={12}>
              <h1 className="text-center sectitle mb-2">
                Explorez les produits de E-triangle par categories{" "}
              </h1>
            </Col>
          </Row>

          <Row>
            <Col sm={12} md={3}>
              <Card className="border-0 shadow-sm categorieCard" >
                <Card.Body className="text-center">
                  <i class="fa-solid fa-children fs-1 text-danger"></i>
                  <Card.Title>Mode Enfants</Card.Title>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={3}>
              <Card className="border-0 shadow-sm categorieCard" >
                <Card.Body className="text-center">
                  <i class="fa-solid fa-person-dress fs-1 text-danger"></i>
                  <Card.Title>Mode Femmes</Card.Title>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={3}>
              <Card className="border-0 shadow-sm categorieCard" >
                <Card.Body className="text-center">
                  <i class="fa-solid fa-vest fs-1 text-danger"></i>
                  <Card.Title>Mode Homme</Card.Title>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={3}>
              <Card className="border-0 shadow-sm categorieCard" >
                <Card.Body className="text-center">
                  <i class="fa-solid fa-headphones-simple fs-1 text-danger"></i>
                  <Card.Title>Chaussures et accessoires</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
}

export default Categorie;
