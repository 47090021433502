import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";

function FaqHeaderPage(props) {
  return (
    <section className="FaqHeaderPageSection">
      <Container>
        <Row>
          <Col>
            <h1 className="text-center ftTitle">Foire aux questions</h1>
            <h3 className="text-center">
              Le moyen le plus simple de demarrer un bussness en ligne
            </h3>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default FaqHeaderPage;
