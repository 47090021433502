import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./styles.scss";
function Seller(props) {
  return (
    <Fragment>
      <section className="productStyle mb-5 mainSection  parallax">
        <Container>
          <Row>
            <Col className="" sm={12} md={5}>
              {" "}
              <img
                alt="DropShiping."
                src="/bg-stock.jpg"
                className="img-fluid  p-3 mb-5 bg-bodyx imgCard animate__animated animate__backInLeft  animate__delay-1s"
              />
            </Col>
            <Col  sm={12} md={7} className="animate__animated animate__backInRight  animate__delay-1s">
            
              <h1 className="sectitle">
                {/* Quels sont les avantages pour le vendeur ? */}
               Les avantages pour le vendeur.
              </h1>
              <ul className="navIco">
                <li>
                  <i class="bi bi-check-circle-fill iCon"></i> Nul besoin d'
                  avoir un fond de commerce{" "}
                </li>
                <li>
                  <i class="bi bi-check-circle-fill iCon"></i> Pas de soucis
                  d'invendus{" "}
                </li>
                <li>
                  <i class="bi bi-check-circle-fill iCon"></i> Pas de soucis de
                  gestion de stock{" "}
                </li>
                <li>
                  <i class="bi bi-check-circle-fill iCon"></i> Livraison gérée à
                  100% par le fourniseur
                </li>
                <li>
                  <i class="bi bi-check-circle-fill iCon"></i> Paiement du
                  client à la livraison{" "}
                </li>
              </ul>

              <blockquote className="blockquote">
                <p>Le dropshipping, une manière intelligente de se faire de</p>
                l'argent
              </blockquote>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
}

export default Seller;
