import React from "react";
import { Fragment } from "react";
import Howtodo from "../howtodo/Howtodo";
import Seller from "../seller/Seller";
import Dropshiper from "../dropshiper/Dropshiper";
import Categorie from "../categories/Categorie";
import Community from "../community/Community";
import Banner from "../banner/Banner";

function Home(props) {
  return (
    <Fragment>
      <Banner />
      <Howtodo />
      <Seller />
      <Dropshiper />
      <Categorie />
      <Community />
    </Fragment>
  );
}

export default Home;
